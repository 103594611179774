<template>
  <div>
    <window-height>
      <container-section :container-class="'container md:my-auto items-center md:items-start'">
        <slide-one
          :slideOneTitleOne="'We want to create '"
          :slideOneTitleTwo="' magically alluring'"
          :slideOneTitleThree="'software.'"
          :slideOneSideImage="'robot.gif'"
        />
      </container-section>
    </window-height>

    <window-height>
      <container-section
        :containerClass="'container-fluid'"
        :sectionHeight="false"
        :backgroundColor="''"
        :imageName="'sfvSDF.webp'"
      >
        <div class="container">
          <div
            data-aos="fade-right"
            data-aos-delay="300"
            class="slide-title-xl text-white w-3/5 pt-28 sm:pt-36"
          >Let's create something together.</div>
        </div>
      </container-section>
    </window-height>

    <container-section :title="'Our Story.'" :backgroundColor="'#FCFCFC'" :sectionHeight="false">
      <div class="our-story">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="slide-title font-black pt-5 pb-5"
        >Our Story.</div>
        <div class="row">
          <div class="col-md-8">
            <div class="our-story-text m-2 flex flex-col sm:flex-row">
              <p
                data-aos="fade-up"
                data-aos-delay="350"
                class="flex-1"
              >We help people Re-Imagine their business and make them future ready. We present you the hard truth and a way to conquer it.</p>
              <p
                data-aos="fade-up"
                data-aos-delay="400"
                class="flex-1"
              >Magure is made out of two words. Magic + Allure. We build software that works seamlessly and make you feel like magic.</p>
            </div>
            <div class="our-story-text m-2 flex flex-col sm:flex-row">
              <p
                data-aos="fade-up"
                data-aos-delay="450"
                class="flex-1"
              >With a vision in mind of deliverying high quality products for our customers, we started our journey.</p>
              <p
                data-aos="fade-up"
                data-aos-delay="500"
                class="flex-1"
              >Gathered a team of highly skilled talented developers working 24*7 across the globe.</p>
            </div>
            <div class="our-story-text m-2 flex flex-col sm:flex-row">
              <p
                data-aos="fade-up"
                data-aos-delay="550"
                class="flex-1"
              >Since inception we are helping clients create the future. We partner with companies to simplify and transform their business.</p>
              <p
                data-aos="fade-up"
                data-aos-delay="600"
                class="flex-1"
              >We value relations, trust and want to spread happiness. It does not matter if your business is having a bad time, Magure will always stand strong, beside your team.</p>
            </div>
          </div>
          <div class="col-md-4 flex flex-col items-center">
            <div class="our-story-img mb-5">
              <img
                data-aos="zoom-in-right"
                data-aos-delay="700"
                src="../assets/images/PNG_MAGUREINC.webp"
                alt
              />
              <div data-aos="fade-up" data-aos-delay="700" class="img-title">Magure, Inc.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="our-process">
        <div data-aos="fade-up" class="slide-title font-black pt-5 pb-5">Our Core Values.</div>

        <div class="row process-content flex flex-wrap justify-center">
          <div class="col-sm-12 col-md-6 mb-4" v-for="item in OurProcessData" :key="item.id">
            <card-component
              data-aos="fade-up"
              data-aos-delay="300"
              :title="item.title"
              :description="item.text"
            />
          </div>
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :sectionHeight="false">
      <div class="container ourServices mt-5 py-16 pt-16">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="slide-title font-black"
        >Are you looking for services</div>
        <div data-aos="fade-up" data-aos-delay="350" class="slide-title font-black pb-5">like ours?</div>
        <base-button
          data-aos="fade-right"
          data-aos-delay="300"
          :href="'/contact-us'"
          id="GetInTouchoneAbout"
          class="btn-primary-dark"
          :isBorderRadiud="false"
        >Get in Touch</base-button>
      </div>
      <div class="about-footer relative">
        <Footer></Footer>
      </div>
    </container-section>
  </div>
</template>

<script>
import CardComponent from "../components/AboutUs/CardComponent.vue";
import ContainerSection from "../components/ContainerSection.vue";
import SlideOne from "../components/Home/SlideOne.vue";
import DataServies from "../services/DataService";
import Footer from "../components/Home/Footer";
import BaseButton from "../components/BaseButton.vue";
import WindowHeight from "../components/WindowHeight.vue";
export default {
  title: "Magure - About us",

  components: {
    SlideOne,
    ContainerSection,
    CardComponent,
    Footer,
    BaseButton,
    WindowHeight
  },

  data() {
    return {
      OurProcessData: DataServies.ourProcess
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/components/AboutUs/AboutUs.scss";
</style>